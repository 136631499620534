<template>
  <div class="wrapper fadeIn">
    <head-component/>
    <section class="for-whom-block">
      <h2>Контакты:</h2>
      <p>Фактический адрес: 115093, Москва г, вн.тер.г. муниципальный округ Даниловский, переулок Партийный, д.1, корп.58, стр.2, этаж а4, офис 26</p>
      <p>Электронная почта: info@edu-oko.ru</p>
      <p>Телефон: +7 (495) 720-55-84</p>
      <h2 style="margin-top: 40px;">Реквизиты:</h2>
      <p>Общество с ограниченной ответственностью «Оценка качества образования» (ООО «ОКО»)</p>
      <p>Генеральный директор: Костюк Александр Витальевич</p>
      <p>ИНН 7729628406</p>
      <p>КПП 772501001</p>
      <p>ОГРН 1097746077582</p>
      <p>Юридический адрес: 115093, Москва г, вн.тер.г. муниципальный округ Даниловский, переулок Партийный, д.1, корп.58, стр.2, этаж а4, офис 26</p>
      <p>Телефон: +7 (495) 720-55-84</p>
    </section>
  </div>
</template>

<script>
import HeadComponent from "../components/HeadComponent";
export default {
  name: "Contact",
  components: {HeadComponent}
}
</script>

<style scoped>

</style>